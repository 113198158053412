<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Using the data in the table below, answer each of the questions below:</p>
      <p class="mb-5 pl-8">
        <chemical-latex content="3A + B -> C" />
      </p>

      <table class="table table-sm table-hover border mb-4" style="width: 400px">
        <thead class="thead-dark text-center br">
          <th>
            <stemble-latex content="$\text{Trial}$" />
          </th>
          <th>
            <stemble-latex content="$\text{[A] (M)}$" />
          </th>
          <th>
            <stemble-latex content="$\text{[B] (M)}$" />
          </th>
          <th>
            <stemble-latex content="$\text{Rate (M/s)}$" />
          </th>
        </thead>
        <tbody>
          <tr>
            <td style="vertical-align: top; text-align: center">
              <stemble-latex content="$1$" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="rate1.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top; text-align: center">
              <stemble-latex content="$2$" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="Bconc2.toFixed(3)" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="rate2.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top; text-align: center">
              <stemble-latex content="$3$" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="Aconc3.toFixed(3)" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="Bconc2.toFixed(3)" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="rate3.toFixed(5)" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: top; text-align: center">
              <stemble-latex content="$4$" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="Aconc3.toFixed(3)" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="vertical-align: top; text-align: center">
              <latex-number :number="rate4.toFixed(5)" />
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-n2">a) What is the order with respect to reactant A?</p>

      <v-radio-group v-model="inputs.orderA" :disabled="!allowEditing" class="pl-8">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p class="mb-n2">b) What is the order with respect to reactant B?</p>

      <v-radio-group v-model="inputs.orderB" :disabled="!allowEditing" class="pl-8">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
        />
      </v-radio-group>

      <p>c) What is the rate constant for the reaction?</p>
      <calculation-input
        v-model="inputs.rateConstantAnswer"
        prepend-text="$k:$"
        append-text="$\text{Units not provided}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question151',
  components: {
    StembleLatex,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        orderA: null,
        orderB: null,
        rateConstantAnswer: null,
      },
      options: [
        {condition: 'Zeroth Order', value: '0'},
        {condition: 'First Order', value: '1'},
        {condition: 'Second Order', value: '2'},
        {condition: 'Third Order', value: '3'},
      ],
    };
  },
  computed: {
    Aconc1() {
      return this.taskState.getValueBySymbol('Aconc1').content;
    },
    Bconc1() {
      return this.taskState.getValueBySymbol('Bconc1').content;
    },
    scaleA() {
      return this.taskState.getValueBySymbol('scaleA').content;
    },
    scaleB() {
      return this.taskState.getValueBySymbol('scaleB').content;
    },
    orderA() {
      return this.taskState.getValueBySymbol('orderA').content.value;
    },
    orderB() {
      return this.taskState.getValueBySymbol('orderB').content.value;
    },
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    rate1() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB
      );
    },
    Bconc2() {
      return this.Bconc1.toFloat() * this.scaleB.toFloat();
    },
    rate2() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc2 ** this.orderB
      );
    },
    Aconc3() {
      return this.Aconc1.toFloat() * this.scaleA.toFloat();
    },
    rate3() {
      return this.rateConstant.toFloat() * this.Aconc3 ** this.orderA * this.Bconc2 ** this.orderB;
    },
    rate4() {
      return (
        this.rateConstant.toFloat() *
        this.Aconc3 ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB
      );
    },
  },
};
</script>
